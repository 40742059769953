.loginContainer{
  height: 100vh;
  background-color: #EEF2F8;
  color: #313036;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .header{
    text-align: center;
    font-size: 24px;
    font-weight: 530;
    color: rgba(0,0,0,0.8);
    font-family: PingFang;
    font-weight: bold;
    line-height: 56px;
    letter-spacing: 2px;
    position: fixed;
    left: 51px;
    top: 22px;
    img {
      margin-right: 12px;
    }
  }
  .footer {
    position: absolute;
    bottom: 24px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.3);
  }
  .loginCon{
    width: 480px;
    height: 535px;
    border-radius: 12px;
    background-color: #ffffff;
    background: rgba(255,255,255,1);
    box-shadow: 0px 12px 24px 0px rgba(54, 64, 81, 0.02);
    padding: 24px;
    position: relative;
    .checkType {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      z-index: 1000;
    }

  }

  .loginWay{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    .title {
      width: 120px;
      height: 33px;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.8);
      line-height: 33px;
      margin:62px 0 56px 0;
    }
    .loginType{
      padding-right: 64px;
      text-align: right;
      position: relative;
      .status {
        display: inline-block;
        padding:2.5px 8px;
        background: rgba(74, 142, 250, 0.1);
        border: 1px solid #4A8EFA;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4A8EFA;
        cursor: pointer;
      }

      // 三角形
      .triangle,.triangle:after{
          width: 0;
          height: 0;
          border-style: solid;
          border-color: transparent;
          position: absolute;
          right: 55px;
          margin-left: -2px;
          top:50%;
          margin-top: -6px;
          display: block;
      }
      .triangle{
          border-left-color: #4A8EFA;
          border-width: 5px;
      }
      .triangle:after{
          content: '';
          border-width: 4px;
          border-left-color: #f3f3f3;
          margin-top: -4px;
          right: -3px;
      }
    }
    .code{
      text-align: center;
      position: relative;
      height: 210px;
      .qrModel{
          width: 200px;
          height: 200px;
          position: absolute;
          left: 120px;
          top: 0px;
          background-color: rgba(255,255,255,0.95);
          font-size: 14px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
      }
      .code_failure {
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8);
        line-height: 22px;
        margin-bottom: 8px;
      }
      .re_loading {
        width: 88px;
        height: 32px;
        line-height: 32px;
        background: #FFFFFF;
        border-radius: 2px;
        border: 1px solid #4A8EFA;
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4A8EFA;
        margin: 0;
      }
    }
    .hint{
        text-align: center;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 22px;
        letter-spacing: 0px;
    }

    // 手机验证码登录
    .phone_form {
      .inputClass {
        height: 48px;
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: none !important;
       > input {
        padding-left: 16px;
       }
      }

      .login_btn {
        height: 48px;
        background: #4A8EFA;
        border-radius: 4px;
        width: 100%;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        margin-top: 88px;
      }
      .phone_code {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.3);
        cursor: pointer;
      }
    }
  }
}
