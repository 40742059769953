@import '~antd/dist/antd.less';
@import '@/assets/style/variable.less';

body {
  overflow-y: hidden;
}

.confirm-modal {
  // background-color: red !important;
  .ant-modal-content {
    padding: 24px;
    border-radius: 4px;

    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-confirm-btns {
      .ant-btn {
        margin-left: 24px !important;
      }
    }

    .ant-modal-confirm-body {
      padding: 24px;

      .ant-modal-confirm-title {
        font-size: 15px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8);
        line-height: 21px;
      }

      .desc-content {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 20px;
      }
    }
  }
}

.ant-modal-content {
  padding: 0 16px;
}

// 去除table 右侧的线
.ant-table-cell {
  &::before {
    display: none;
  }
}

// 表格 start
.operate {
  color: @ft-color-blue;
  span:nth-child(even) {
    color: @bd-grey;
    margin: 0 8px;
  }
}
.ant-table-wrapper {
  margin-top: 16px;
}
// 表格 end


.sku-popover-container {
  display: flex;
  flex-direction: column;
  user-select: none;

  .title-container {
    height: 32px;
    background-color: rgba(0, 0, 0, 0.04);
    display: flex;
    align-items: center;

    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);

    .title-item {
      padding: 0 10px;
      width: 130px;
      // min-width: 100px;
    }
  }

  .body-container {
    display: flex;
    flex-direction: column;
    user-select: none;


    .row-cell {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #e5e5e5;

      .sku-main {
        min-height: 32px;
        width: 130px;
        padding: 0 10px;
        border-right: 1px solid #e5e5e5;
        display: flex;
        // justify-content: center;
        align-items: center;
      }

      .sku-min-container {
        display: flex;
        flex-direction: column;

        .sku-row {
          display: flex;
          flex-direction: row;
          height: 32px;
          border-bottom: 1px solid #e5e5e5;

          &:last-child {
            border-bottom: none;
          }


        }
      }

      .item {
        width: 130px;
        padding: 0 10px;
        line-height: 30px;

        &:first-child {
          border-right: 1px solid #e5e5e5;
        }
      }


    }
  }

  .button-row {
    padding-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    Button {
      &:last-child {
        margin-left: 8px;
      }
    }
  }
}
